//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
import cookies from "vue-cookies";
import HttpStatus from "http-status-codes";
import { version } from "../../package";

export default {
  data: () => ({
    appVersion: version,
    appNewVersion: "",
    appVUpdate: cookies.get("ACVUpdate"),
    authcode: cookies.get("ACValue"),
    user: "",
    password: "",
    show1: false,
    show1no: false,
    //publicIP: "",
    errorStr: null,
    dialogText: "",
    dialogInfo: false,
    dialogUpdate: false,
    remain: 0,
    gettingLocation: false,
    gpsLoc: "",
    form: false,
    rules: {
      required: (v) => !!v || "This field is required",
    },
  }),
  created() {
    this.getGPSLoc();
  },
  methods: {
    notNull(value) {
      if (value !== null) {
        return value;
      } else {
        return "";
      }
    },
    ferlogin() {
      var self = this;

      if (self.authcode != null) {
        cookies.set("ACValue", self.authcode, "30d");
      }

      self.getGPSLoc();
      /*
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.publicIP = ip;
          console.log(ip);
        });
*/

      axios
        .post(api.URL() + "/api/v1/getlogin", {
          authCode: self.authcode,
          user: self.user,
          password: self.password,
          gpsLoc: self.gpsLoc,
        })
        .then((response) => {
          sessionStorage.setItem("UserId", response.data.usuari.id);
          sessionStorage.setItem("UserName", response.data.usuari.username);
          sessionStorage.setItem("Name", response.data.usuari.name);
          sessionStorage.setItem("Token", response.data.token);
          self.appNewVersion = response.data.usuari.updateDate
          if (
            self.appNewVersion != "" &&
            self.appNewVersion != self.appVUpdate
          ) {
            //self.$router.push("/update?version=" + response.data.usuari.updateDate);
            this.dialogUpdate = true
          } else {
            self.$router.push("/mainmenu");
          }
        })
        .catch(function (error) {
          self.dialogText = "Invalid user or password";
          if (error.response.status == HttpStatus.MOVED_PERMANENTLY) {
            self.dialogText = `User: ${error.response.data} has been locked out, contact your system administrator for more information`;
          }
          if (error.response.status == HttpStatus.LOCKED) {
            self.dialogText = `Your access has been locked due to multiple failed login attempts, remain time: ${error.response.data} seconds`;
          }
          self.dialogInfo = true;
        });
    },
    cookieRemove() {
      self.cookies.remove("ACValue");
    },

    getGPSLoc() {
      if (!("geolocation" in navigator)) {
        return;
      }

      this.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.gpsLoc = pos.coords.latitude + ", " + pos.coords.longitude;
        },
        (err) => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },
    goAppUpdate() {
      cookies.set("ACVUpdate", this.appNewVersion, Infinity);
      window.location.reload(true);
      //this.$forceUpdate();
      //naForceUpdate(this.appNewVersion);
      //this.$root.$emit('component1');
    },
    goAppCancelUpdate() {
      this.$router.push("/mainmenu");
    },
  },
};
